import React, { FC, ReactNode, useState, useContext } from 'react';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';
import NextLink from 'next/link';

import { Button, Tooltip, Badge, Collapse, ListItem, styled, TooltipProps, tooltipClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({ children, link, icon: Icon, badge, badgeTooltip, open: openParent, active, name, ...rest }) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(!!openParent);
  const { t }: { t: any } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component='div' className='Mui-children' key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
          onClick={toggleMenu}
          sx={{ letterSpacing: '0.05rem', textAlign: 'left' }}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement='right'>
              {badge === '' ? <Badge color='primary' variant='dot' /> : <Badge badgeContent={badge} />}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color='primary' variant='dot' />
          ) : (
            <Badge badgeContent={badge} />
          )}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component='div' key={name} {...rest}>
      <NextLink href={link} passHref style={{ width: '100%', textDecoration: 'none' }}>
        <Button
          disableRipple
          className={clsx({ 'Mui-active': active })}
          onClick={closeSidebar}
          startIcon={Icon && <Icon />}
          sx={{ letterSpacing: '0.05rem', textAlign: 'left' }}
        >
          {t(name)}
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement='right'>
              {badge === '' ? <Badge color='primary' variant='dot' /> : <Badge badgeContent={badge} />}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color='primary' variant='dot' />
          ) : (
            <Badge badgeContent={badge} />
          )}
        </Button>
      </NextLink>
    </ListItem>
  );
};

export default SidebarMenuItem;
