import {RawAxiosRequestConfig} from 'axios';

import HttpService from '../httpService';

import {IPagination} from './types';

export interface IOrdersSeries {
  categories: Array<string>;
  series: Array<number>;
}

export interface IOrders {
  id: number;
  name: string;
  quoteId: number;
  approved: boolean;
  closedAt?: Date;
  completedAt?: Date;
  etc?: Date;
  createdAt: Date;
  createdBy: string;
  imported: boolean;
  processed: boolean;
  toCustomer: {id: number; name: string};
  fromCustomer: {id: number; name: string};
}

export interface IOrdersPaginated extends IPagination<IOrders> {}

export interface IWorkOrderMarksAvailable {
  id: number;
  availableQuantity: number;
  name: string;
  quantity: number;
  type: string;
}

export const orderResource = 'ProductionOrder';
export const approveOrderResource = 'ProductionApproveOrder';
export const importOrderResource = 'ProductionImportOrder';
export const invoiceOrderResource = 'ProductionInvoiceOrder';
export const etcOrderResource = 'ProductionOrderEtc';

export default new (class OrderService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Orders';

  async getOrdersCount(): Promise<number> {
    const response = await this.getBase<{total: number}>(`${this.url}/Count`);

    return response.data.total;
  }

  async getOrdersMonthly(): Promise<IOrdersSeries> {
    const response = await this.getBase<IOrdersSeries>(`${this.url}/Monthly`);

    return response.data;
  }

  async getOrdersDaily(): Promise<IOrdersSeries> {
    const response = await this.getBase<IOrdersSeries>(`${this.url}/Daily`);

    return response.data;
  }

  async getOrders(page: number, pageSize: number, searchTerm: number | undefined | string, filters: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        approved: filters?.approved,
        imported: filters?.imported,
        processed: filters?.processed,
        hasInvoice: filters?.hasInvoice,
        closedAt: filters?.closedAt,
        completedAt: filters?.completedAt,
        quoteId: filters?.quoteId,
        name: filters?.name,
        toCustomerId: filters?.toCustomerId,
        createdBy: filters?.createdBy,
        creationStart: filters?.creationStart,
        creationEnd: filters?.creationEnd,
        etcStart: filters?.etcStart,
        etcEnd: filters?.etcEnd,
      };
    }

    const response = await this.getBase<IOrdersPaginated>(this.url, config);

    return response.data;
  }

  async getAvailableMarks(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/${id}/AvailableMarks`);

    return data;
  }
})();
