import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface ICustomer {
  id: string;
  parentId: number;
  name: string;
  phone: string;
  markup: number;
  disable: boolean;
  parentName: string;
  paymentTerm: {id: number; name: string};
  deliveryType: {id: number; name: string};
  taxType: {id: number; name: string; value: number};
  dynamicNumber: string;
  emails: Array<{
    id: number;
    address: string;
    emailType: {id: number; name: string; isShared: boolean};
  }>;
  addresses: Array<{
    id: number;
    main: string;
    city: string;
    zipCode: string;
    isUsa: boolean;
    state: {id: number; name: string};
    addressType: {id: number; name: string};
  }>;
  logo: {name: string; url: string; hasPlaceHolder: boolean};
}
export interface ICustomerPaginated extends IPagination<ICustomer> {}

export const customerResource = 'CustomersCustomer';

class CustomerService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Customers';

  async getCustomers(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        parentCustomerId: filters?.parentCustomerId,
      };
    }

    const response = await this.getBase<ICustomerPaginated>(this.url, config);
    return response.data;
  }

  async getChildListIncludingCurrent() {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/ChildListIncludingCurrent`);

    return response.data;
  }

  async getCustomersCount() {
    const response = await this.getBase<{total: number}>(`${this.url}/Count`);

    return response.data.total;
  }

  disableCustomer(customerId: number) {
    return `${this.url}/${customerId}/Disable`;
  }

  enableCustomer(customerId: number) {
    return `${this.url}/${customerId}/Enable`;
  }

  async getCurrentCustomerDetail() {
    const {data} = await this.getBase(`${this.url}/Current`);

    return data;
  }

  async updateCustomerProfile(logo: string) {
    const {data} = await this.putBase(`${this.url}/CurrentProfile`, logo);

    return data;
  }
}
export default new CustomerService();
