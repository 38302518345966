import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IPaymentTerm {
  id: number;
  name: string;
  value: number;
  isShared: boolean;
}

export interface IPaymentTermsPaginated extends IPagination<IPaymentTerm> {}

export const paymentTypeResource = 'SettingsPaymentTerm';

export default new (class PaymentService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/PaymentTerms';

  async getPaymentTerms(page: number, pageSize: number): Promise<IPaymentTermsPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IPaymentTermsPaginated>(this.url, config);

    return response.data;
  }

  async getPaymentTermsByCustomer(customerId: number) {
    const response = await this.getBase<Array<IPaymentTerm>>(`${this.url}/Customer/${customerId}`);

    return response.data;
  }
})();
