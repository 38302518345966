import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IPackageSlip {
  approved: boolean;
  createdAt: string;
  createdBy: string;
  customerName: string;
  delivered: boolean;
  id: number;
  orderId: number;
  quoteId: number;
  quoteName: string;
  dispatched: boolean;
}

export const packingSlipResource = 'ShippingPackingSlip';
export const dispatchPackingSlipResource = 'ShippingDispatchPackingSlip';

export default new (class PackingSlipService extends HttpService {
  constructor() {
    super();

    this.signPackingSlip = this.signPackingSlip.bind(this);
  }

  url = '/v1/PackingSlips';

  async getPackingSlips(page: number, pageSize: number, searchTerm: number | undefined, filters: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        orderId: filters?.orderId,
        quoteId: filters?.quoteId,
        quoteName: filters?.quoteName,
        approved: filters?.approved,
        dispatched: filters?.dispatched,
        createdBy: filters?.createdBy,
        creationStart: filters?.creationStart,
        creationEnd: filters?.creationEnd,
      };
    }

    const {data} = await this.getBase<IPagination<any>>(this.url, config);

    return data;
  }

  async getPackingSlipReport(packingSlipId: number, format: any) {
    const {data} = await this.getBase(process.env.NEXT_PUBLIC_REPORT_URL + '/packingSlips', {
      params: {
        format,
        packingSlipId,
        apiUrl: process.env.NEXT_PUBLIC_API_URL,
      },
    });

    return data;
  }

  async getPackingSlipStatus(packingSlipId: number, packageId: number) {
    const {data} = await this.getBase<any>(`${this.url}/Status`, {
      params: {
        packingSlipId,
        packageId,
      },
    });

    return data;
  }

  async signPackingSlip(params: {data: any; id: number}) {
    const {data: response} = await this.putBase(`${this.url}/${params.id}/Sign`, params.data);

    return response;
  }
})();
