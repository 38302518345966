import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IItemColor {
  id: number;
  name: string;
  code: string;
}

export interface IItemColorPaginated extends IPagination<IItemColor> {}

export const itemColorResource = 'SettingsItemColor';

class ItemColorService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Colors';

  getColors = async (page: number, pageSize: number, searchTerm: string | undefined, filters?: any): Promise<IItemColorPaginated> => {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        code: filters?.code,
      };
    }

    const response = await this.getBase<IItemColorPaginated>(this.url, config);

    return response.data;
  };

  getAllItemColors = async (): Promise<Array<IAutoComplete>> => {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/List`);

    return response.data;
  };
}

export default new ItemColorService();
