import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export const roleResource = 'AccountRoles';

export interface IPermissionGroup {
  group: string;
  permissions: Array<{
    resource: string;
    actions: Array<{name: string; value: boolean}>;
  }>;
}

export interface IRole {
  id: string;
  name: string;
  group: {id: number; name: string};
  permissionGroups: IPermissionGroup[];
}

export interface IRolePaginated extends IPagination<{id: string; name: string; group: {id: number; name: string}}> {}

class RoleService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Roles';

  permissionsUrl(roleId: number) {
    return `${this.url}/${roleId}/Permissions`;
  }

  async getRoles(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        groupId: filters?.groupId,
      };
    }

    const response = await this.getBase<IRolePaginated>(this.url, config);

    return response.data;
  }

  async getAllRoles() {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/All`);

    return response.data;
  }

  async getRoleGroups() {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/Groups`);

    return response.data;
  }
}

export default new RoleService();
