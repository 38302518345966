import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IWorkOrders {
  closedAt?: boolean;
  completedAt?: boolean;
  createdAt: Date;
  createdBy: string;
  etc?: Date;
  id: number;
  identifier: number;
  index: number;
  orderId: number;
  quoteId: number;
  quoteName: string;
  release: string;
  toCustomer: string;
}

export interface IWorkOrderPaginated extends IPagination<IWorkOrders> {}

export const workOrderResource = 'ProductionWorkOrder';
export const smallLabelResource = 'ProductionSmallLabel';
export const releaseWorkOrderResource = 'ProductionReleaseWorkOrder';
export const completeWorkOrderResource = 'ProductionCompleteWorkOrder';

export default new (class WorkOrderService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/WorkOrders';

  async getWorkOrders(page: number, pageSize: number, searchTerm: number | undefined | '', filters?: any): Promise<IWorkOrderPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        quoteId: filters?.quoteId,
        name: filters?.name,
        toCustomerId: filters?.toCustomerId,
        release: filters?.release,
        createdBy: filters?.createdBy,
        creationStart: filters?.creationStart,
        creationEnd: filters?.creationEnd,
        closedAt: filters?.closedAt,
        completedAt: filters?.completedAt,
      };
    }

    const response = await this.getBase<IWorkOrderPaginated>(this.url, config);
    return response.data;
  }

  //Reports
  async getReport(id: number) {
    const config: RawAxiosRequestConfig = {
      responseType: 'arraybuffer',
    };

    const response = await this.getBase<any>(`${this.url}/${id}/Print`, config);

    return response.data;
  }
})();
