import React, {useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import {styled, useTheme, alpha} from '@mui/material/styles';

import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

import useAuth from '@/hooks/useAuth';
import Permissions from '@/components/Utilities/Permissions';

import GenericAction from '@/components/Actions/generic/GenericAction';
import CustomerInformationForm, {logoValidation} from '@/content/customers/CustomerInformationForm';
import {useMutation} from '@tanstack/react-query';
import {useSnackbar} from 'notistack';
import {useAppSelector} from '@/redux/store';

import customerService from '@/services/http/CustomerService';

const UserBoxButton = styled(IconButton)(
  ({theme}) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({theme}) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({theme}) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({theme}) => `
        color: ${theme.palette.secondary.light}
`
);

const HeaderUserBox = () => {
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;
  const {t}: {t: any} = useTranslation();
  const theme = useTheme();
  const auth = useAuth();

  const authState = useAppSelector((state: any) => state.auth);
  const {accessToken} = authState;
  const {mutateAsync} = useMutation({mutationFn: customerService.updateCustomerProfile});
  const {enqueueSnackbar} = useSnackbar();

  const user = authState.isAuthenticated
    ? {
        initials: `${accessToken.decodedToken.firstName[0]}${accessToken.decodedToken.lastName[0]}`,
        name: `${accessToken.decodedToken.firstName} ${accessToken.decodedToken.lastName}`,
        roles: `${accessToken.decodedToken.role.join(', ')}`,
      }
    : {
        initials: '',
        name: '',
        roles: '',
      };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    handleClose();
    await auth.logout();
  };

  const swaggerNavigation = async () => {
    handleClose();
    await navigator.clipboard.writeText(accessToken.token);
    window.open(`${apiUrl}/swagger/index.html`, '_blank');
  };

  const hangfireNavigation = async () => {
    handleClose();
    window.open(`${apiUrl}/hangfire?accessToken=${accessToken.token}`, '_blank');
  };

  const handleSubmit = async (data: any) => {
    try {
      await mutateAsync(data);
      enqueueSnackbar('The record was successfully edited.', {variant: 'success'});
    } catch (error) {
      enqueueSnackbar('Something was wrong.', {variant: 'error'});
    }
  };

  return (
    <>
      <UserBoxButton color='primary' ref={ref} onClick={handleOpen}>
        <Avatar variant='rounded' sx={{background: `${theme.colors.primary.main}`, color: `${theme.colors.alpha.white[100]}`}}>
          {user.initials}
        </Avatar>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <MenuUserBox sx={{minWidth: 210}} display='flex'>
          <Avatar variant='rounded' sx={{background: `${theme.colors.primary.main}`, color: `${theme.colors.alpha.white[100]}`}}>
            {user.initials}
          </Avatar>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.roles}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{mb: 0}} />
        <MenuListWrapperPrimary disablePadding>
          <GenericAction
            title='Customer Information'
            form={CustomerInformationForm}
            validationSchema={logoValidation}
            onSuccess={handleSubmit}
            maxWidth={'sm'}
            data={{action: 'edit'}}
          >
            <MenuItem>
              <ListItemText primaryTypographyProps={{variant: 'h5'}} primary={'Company Information'} />
            </MenuItem>
          </GenericAction>
          <Permissions resource='Hangfire'>
            <MenuItem onClick={hangfireNavigation}>
              <ListItemText primaryTypographyProps={{variant: 'h5'}} primary='Hangfire' />
            </MenuItem>
          </Permissions>
          <Permissions resource='Swagger'>
            <MenuItem onClick={swaggerNavigation}>
              <ListItemText primaryTypographyProps={{variant: 'h5'}} primary='Swagger' />
            </MenuItem>
          </Permissions>
        </MenuListWrapperPrimary>
        <Divider />
        <Box m={1}>
          <Button color='primary' fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{mr: 1}} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserBox;
