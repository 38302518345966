import {ElementType, MouseEvent, forwardRef, ForwardedRef} from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface ITooltipActionProps {
  title: string;
  icon: ElementType;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  displayAsMenu?: boolean;
  disabled?: boolean;
}

const TooltipAction = forwardRef((props: ITooltipActionProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const {title, icon: Icon, onClick, displayAsMenu = false, disabled} = props;

  if (displayAsMenu) {
    return (
      <MenuItem onClick={onClick} disabled={disabled} sx={{minHeight: 40, paddingLeft: 1.2, paddingRight: 1.2}}>
        <ListItemIcon>
          <Icon fontSize='small' />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    );
  }

  return (
    <Tooltip sx={{m: 0.2}} title={title} arrow placement='top'>
      <IconButton ref={ref} onClick={onClick} disabled={disabled}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
});

export default TooltipAction;
