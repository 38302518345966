import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IItemTypes {
  id: number;
  name: string;
}

export interface IItemTypePaginated extends IPagination<IAutoComplete> {}

export const itemTypeResource = 'SettingsItemType';

class ItemTypeService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/ItemTypes';

  async getItemTypes(page: number, pageSize: number): Promise<IItemTypePaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IItemTypePaginated>(this.url, config);

    return response.data;
  }

  async getItemTypeList() {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/List`);

    return response.data;
  }
}

export default new ItemTypeService();
