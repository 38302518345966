import {useState, createElement, useEffect, Fragment} from 'react';

import {useForm} from 'react-hook-form';

import {zodResolver} from '@hookform/resolvers/zod';
import {ZodSchema} from 'zod';
import {joiResolver} from '@hookform/resolvers/joi';

import FormDialog from '../dialogActions/FormDialog';
import TooltipAction from '../dialogActions/shared/TooltipAction';
import ConfirmDialog from '../dialogActions/ConfirmDialog';

interface IProps {
  title: string;
  tooltip?: string;
  data?: any;
  validationSchema: any;
  contentText?: string;
  validationMode?: 'onChange' | 'onSubmit' | 'onBlur' | 'onTouched';
  maxWidth?: 'xs' | 'xl' | 'sm' | 'md' | 'lg';
  iconSize?: 'small' | 'medium' | 'large';
  form: React.ElementType;
  onSuccess: (data: any) => void;
  onCancel?: () => void;
  icon?: React.ElementType;
  children?: React.ReactChild;
  openDialog?: boolean;
  displayAsMenu?: boolean;
  enableConfirm?: boolean;
  descriptionConfirm?: string;
  shouldBeDirty?: boolean;
}

const GenericAction: React.FC<IProps> = (props) => {
  const {
    title,
    onSuccess,
    onCancel,
    form,
    validationSchema,
    data,
    validationMode = 'onChange',
    contentText,
    maxWidth = 'md',
    icon: Icon,
    children,
    openDialog: openDialogProp = false,
    displayAsMenu,
    enableConfirm = false,
    descriptionConfirm,
    shouldBeDirty,
  } = props;

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState({});

  let resolverType = validationSchema instanceof ZodSchema ? zodResolver(validationSchema) : joiResolver(validationSchema);

  const formMethods = useForm<any>({
    mode: validationMode,
    resolver: resolverType,
  });

  const {formState} = formMethods;

  const handleFormSubmit = (data: any) => {
    onSuccess(data);
    setOpenConfirm(false);
    setOpen(false);

    return;
  };

  const handleConfirmation = (data: any) => {
    setOpenConfirm(true);
    setFormData(data);
  };

  const openDialog = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (openDialogProp) {
      openDialog();
    }
  }, [openDialogProp]);

  const onCancelHandler = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  const renderIcon = () => {
    if (Icon !== undefined) return <TooltipAction title={title} icon={Icon} onClick={openDialog} displayAsMenu={displayAsMenu} />;

    if (children !== undefined)
      return (
        <div style={{cursor: 'pointer'}} onClick={openDialog}>
          {children}
        </div>
      );

    return null;
  };

  const renderForm = () => {
    return createElement(form, {
      data: {...data},
    });
  };

  if (!open) return renderIcon();

  return (
    <Fragment>
      {renderIcon()}
      {enableConfirm && (
        <ConfirmDialog
          description={descriptionConfirm}
          loading={false}
          open={openConfirm}
          onConfirm={() => handleFormSubmit(formData)}
          onCancel={() => setOpenConfirm(false)}
        />
      )}
      <FormDialog
        title={title}
        loading={formState.isSubmitting}
        description={contentText}
        submitText={'OK'}
        open={open}
        onSubmit={enableConfirm ? handleConfirmation : handleFormSubmit}
        onClose={onCancelHandler}
        maxWidth={maxWidth}
        validationSchema={validationSchema}
        formContext={formMethods}
        shouldBeDirty={shouldBeDirty}
      >
        {renderForm()}
      </FormDialog>
    </Fragment>
  );
};

export default GenericAction;
