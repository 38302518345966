import {RawAxiosRequestConfig} from 'axios';

import HttpService from '../httpService';
import {IPagination} from './types';

export interface IAudit {
  id: number;
  action: string;
  keyValue: string;
  newValue: string;
  oldValue: string;
  tableName: string;
  dateTime: string;
  user: {id: string; firstName: string; lastName: string};
}

export interface IAuditPaginated extends IPagination<IAudit> {}

export const auditResource = 'AuditLogs';

class AuditService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Audits';

  async getAudits(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== undefined) {
      let creationStart = null;

      if (filters['creationStart'] !== undefined) {
        if (filters?.creationStart !== '') creationStart = new Date(filters?.creationStart);
      }

      let creationEnd = null;
      if (filters['creationEnd'] !== undefined) {
        if (filters?.creationEnd !== '') creationEnd = new Date(filters?.creationEnd);
      }

      config.params = {
        ...config.params,
        userId: filters?.userId,
        firstName: filters?.firstName,
        lastName: filters?.lastName,
        keyValue: filters?.keyValue,
        tableName: filters?.tableName,
        action: filters?.action,
        creationStart: creationStart,
        creationEnd: creationEnd,
      };
    }

    const response = await this.getBase<IAuditPaginated>(this.url, config);

    return response.data;
  }
}

export default new AuditService();
