import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IGenericResponse, IPagination} from './types';

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  customer: {id: number; name: string};
  roles: Array<string>;
  disabled: boolean;
}

export interface IUserPaginated extends IPagination<IUser> {}

export const userResource = 'AccountUsers';
export const profileResource = 'AccountProfile';

class UserService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Users';

  async getUsers(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        email: filters?.email,
        roleId: filters?.roleId,
        customerId: filters?.customerId,
      };
    }

    const response = await this.getBase<IUserPaginated>(this.url, config);

    return response.data;
  }

  async forgotPassword(email: string) {
    let response = await this.postBase<IGenericResponse>(`${this.url}/ForgotPassword`, {email});

    return response.data;
  }

  async changePassword(userId: string, token: string, password: string, confirmPassword: string) {
    let response = await this.putBase<IGenericResponse>(`${this.url}/${userId}/ChangePassword`, {
      token,
      password,
      confirmPassword,
    });

    return response.data;
  }

  inviteUser(userId: string) {
    return `${this.url}/${userId}/Invite`;
  }

  enableUser(userId: string) {
    return `${this.url}/${userId}/Enable`;
  }

  disableUser(userId: string) {
    return `${this.url}/${userId}/Disable`;
  }

  async getUsersByCustomerId(customerId: number) {
    const response = await this.getBase(`${this.url}/ByCustomerId/${customerId}`);
    return response.data;
  }
}

export default new UserService();
