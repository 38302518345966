import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IPromotion {
  id: number;
  description: string;
  active: boolean;
}

export interface IPromotionsPaginated extends IPagination<IPromotion> {}

export const promotionResource = 'SettingsPromotion';

export default new (class PromotionService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Promotions';

  async getPromotions(page: number, pageSize: number): Promise<IPromotionsPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IPromotionsPaginated>(this.url, config);

    return response.data;
  }
})();
