import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IPackage {
  allMarkUnitsReceived: boolean;
  orderId: number;
  name: string;
  createdBy: string;
  creationStart: string;
  creationEnd: string;
  hasPackingSlip: boolean;
  id: number;
  quoteId: number;
  quoteName: string;
}

export interface IPackageOrder {
  count: number;
  createdAt: Date;
  createdBy: string;
  hasPackingSlip: boolean;
  id: number;
  markUnits: {
    height: number;
    name: string;
    quantity: number;
    type: string;
    width: number;
    markUnitsId: number[];
  };
}

export interface IPackageView {
  count: number;
  id: number;
  marks: {
    name: string;
    height: number;
    quantity: number;
    type: string;
    width: number;
  }[];
}

export interface IPackageSlipView {
  count: number;
  createdAt: Date;
  createdBy: Date;
  id: number;
  marks: {
    name: string;
    height: number;
    quantity: number;
    type: string;
    width: number;
  }[];
}

export const packageResource = 'ShippingPackage';
export const receivePackageResource = 'ShippingReceivePackage';

export default new (class PackageService extends HttpService {
  constructor() {
    super();

    this.postPackage = this.postPackage.bind(this);
    this.receivePackage = this.receivePackage.bind(this);
  }

  url = '/v1/Packages';

  async getPackagesByOrderId(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/Order/${id}`);

    return data;
  }

  async deletePackage(id: number) {
    const {data: response} = await this.deleteBase(`${this.url}/${id}`);

    return response;
  }

  async getPackages(page: number, pageSize: number, searchTerm: number | undefined, filters: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        hasPackingSlip: filters?.hasPackingSlip,
        orderId: filters?.orderId,
        name: filters?.name,
        createdBy: filters?.createdBy,
        creationStart: filters.creationStart,
        creationEnd: filters.creationEnd,
      };
    }

    const {data} = await this.getBase<IPagination<any>>(this.url, config);

    return data;
  }

  async getPackageById(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/${id}`);

    return data;
  }

  async getPackagesByPackingSlipId(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/PackingSlip/${id}`);

    return data;
  }

  async postPackage(data: any) {
    const {data: response} = await this.postBase(this.url, {
      markUnitIds: data,
    });

    return response;
  }

  async printPackage({id}: {id: number}) {
    const {data} = await this.getBase(`${this.url}/Print/${id}`);

    return data;
  }

  async receivePackage(id: number) {
    const {data: response} = await this.putBase(`${this.url}/${id}/Receive`, {});

    return response;
  }
})();
