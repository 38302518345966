import {FC, ReactNode} from 'react';

import usePermissions from '@/hooks/usePermissions';

export type PermissionResource = 'any' | string | string[];
export type PermissionAction = 'Read' | 'Create' | 'Update' | 'Delete' | 'Filter' | 'Report';

interface PermissionsProps {
  resource: PermissionResource;
  action?: PermissionAction;
  children: ReactNode;
}

const Permissions: FC<PermissionsProps> = (props: any) => {
  const {resource, action, children} = props;

  const permissions = usePermissions();

  return permissions.has(resource, action) ? <>{children}</> : null;
};

export default Permissions;
