import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import Link from '@/components/Link';

import { styled } from '@mui/material/styles';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(1)};
`
);

function Footer() {
  return (
    <FooterWrapper className='footer-wrapper'>
      <Box p={1} display={{ xs: 'block', md: 'flex' }} alignItems='center' textAlign={{ xs: 'center', md: 'left' }} justifyContent='space-between'>
        <Box>
          <Typography variant='subtitle1' sx={{ fontSize: { xs: 12, md: 14 } }}>
            &copy; {new Date().getFullYear()} - Mr-Glass Glass Doors & Windows Manufacturing, LLC
          </Typography>
        </Box>
        <Box sx={{ display: 'inline-flex' }}>
          {/* <Typography variant='subtitle1' sx={{ml: 0.5, mr: 0.5}}>
            <Link underline='hover' href='/info/terms-of-service' rel='noopener noreferrer'>
              Terms of Service
            </Link>
          </Typography> */}
          <Typography variant='subtitle1' sx={{ ml: 0.5, mr: 0.5 }}>
            Crafted by{' '}
            <Link underline='hover' href='https://www.mrglasswindows.com/' target='_blank' rel='noopener noreferrer'>
              Mr-Glass
            </Link>
          </Typography>
        </Box>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
