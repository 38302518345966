import { useAppSelector } from '@/redux/store';
import useAuth from '@/hooks/useAuth';

const usePermissions = () => {
  const auth = useAuth();
  let permissions = useAppSelector((state) => state.auth.permissions);

  if (!Array.isArray(permissions)) {
    auth.logout();
    permissions = [];
  }

  const has = (resource: 'any' | string | Array<string>, action?: string) => {
    if (resource === 'any') {
      return action === undefined ? true : permissions.some((x) => x.action === action);
    }

    if (typeof resource === 'string') {
      return action === undefined
        ? permissions.some((x) => x.resource === resource)
        : permissions.some((x) => x.resource === resource && x.action === action);
    } else if (Array.isArray(resource)) {
      return action === undefined
        ? permissions.some((x) => resource.includes(x.resource))
        : permissions.some((x) => resource.includes(x.resource) && x.action === action);
    }

    return false;
  };

  return {
    has,
  };
};

export default usePermissions;