import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IVendor {
  id: number;
  name: string;
  phone: string;
  email: string;
  tax: number;
  address: {
    address: string;
    zipCode: string;
    city: string;
    state: {id: number; abbreviation: string; name: string};
    isUsa: boolean;
  };
}

export interface IVendorPaginated extends IPagination<IVendor> {}

export interface IVendorItem {
  vendor: {id: number; name: string};
  sku: string;
  leadTime: number;
  cost: number;
}

export interface IVendorItems {
  itemId: number;
  items: Array<IVendorItem>;
}

export interface IVendorItemLine {
  vendorItemList: {
    costPrice: number;
    id: number;
    itemId: number;
    name: string;
    stock: number;
  }[];
}

export const vendorResource = 'InventoryVendor';

export default new (class VendorService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Vendors';

  async getVendors(page: number, pageSize: number, searchTerm: string | undefined): Promise<IVendorPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    const response = await this.getBase<IVendorPaginated>(this.url, config);

    return response.data;
  }

  async getAllVendors(): Promise<Array<IAutoComplete>> {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/All`);

    return response.data;
  }

  async getAllOrderBy(): Promise<Array<IAutoComplete>> {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/OrderByAll`);

    return response.data;
  }

  async getVendorList() {
    const {data} = await this.getBase<any>(`${this.url}/List`);

    return data;
  }

  async getVendorItems(vendorId: number, warehouseId: number) {
    const {data} = await this.getBase<any>(`${this.url}/${vendorId}/Items/${warehouseId}`);

    return data;
  }
})();
