import {useRouter} from 'next/router';

import {useSnackbar} from 'notistack';

import {useMutation} from '@tanstack/react-query';

import {useAppDispatch} from '@/redux/store';
import {addTokens, removeTokens} from '@/redux/slices/authSlice';

import {useMountedRef} from '@/hooks/useMountedRef';

import authService from '@/services/http/AuthService';

const useAuth = () => {
  const dispatch = useAppDispatch();
  const isMountedRef = useMountedRef();
  const router = useRouter();
  const mutation = useMutation({mutationFn: authService.login});
  const {enqueueSnackbar} = useSnackbar();

  const login = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const response = await mutation.mutateAsync({email, password, rememberMe});

      dispatch(addTokens(response));

      if (isMountedRef()) {
        const backTo = (router.query.backTo as string) || '/';
        await router.push(backTo);
      }
    } catch (error: any) {
      if (error.response != null) {
        enqueueSnackbar(error.response.data.errorMessage, {variant: 'error'});
      }
    }
  };

  const logout = async () => {
    try {
      await dispatch(removeTokens());
      await router.push('/auth/login');
    } catch (error: any) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  };

  return {
    login,
    logout,
  };
};

export default useAuth;
