import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { alpha, lighten, useTheme } from '@mui/material/styles';

import Sidebar from './Sidebar';
import Header from './Header';
import Footer from '@/layouts/AuthLayout/Footer';
import { useAppSelector } from '@/redux/store';

interface AuthLayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const [verified, setVerified] = useState(false);

  const theme = useTheme();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const auth = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (!auth.isAuthenticated) {
      (async () => {
        await router.push({
          pathname: '/auth/login',
          query: { backTo: router.asPath },
        });
      })();
    } else {
      setVerified(true);

      enqueueSnackbar('You are successfully authenticated.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 2000,
        TransitionComponent: Slide,
      });
    }
  }, [router.isReady]);

  if (!verified) {
    return null;
  }

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',
        '.MuiPageTitle-wrapper': {
          background: theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[5] : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
          boxShadow:
            theme.palette.mode === 'dark'
              ? '0 1px 0 ' +
              alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
              ', 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)'
              : '0px 2px 4px -3px ' + alpha(theme.colors.alpha.black[100], 0.1) + ', 0px 5px 12px -4px ' + alpha(theme.colors.alpha.black[100], 0.05),
        },
      }}
    >
      <Header />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`,
          },
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box display='block' sx={{ flex: '1 0 auto' }}>
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default AuthLayout;
