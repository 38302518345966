import {FC} from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface LineMenuItemProps {
  text: string;
}

const LineMenuTitle: FC<LineMenuItemProps> = ({text}) => {
  return (
    <>
      <Typography variant='h5' textAlign='center'>
        {text}
      </Typography>
      <Divider sx={{marginTop: 1, marginBottom: 1}} />
    </>
  );
};

export default LineMenuTitle;
