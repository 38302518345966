import { useContext } from 'react';

import { Box, alpha, Stack, lighten, IconButton, Tooltip, styled, useTheme } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderUserBox from './UserBox';
// import HeaderSearch from './Search';
import HeaderMenu from './Menu';
import Logo from '@/components/Logo';

import Permissions from '@/components/Utilities/Permissions';

const staging = process.env.NEXT_PUBLIC_ENV === 'staging';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background ?? '', 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
        border-top: ${staging ? '5px solid #E63332' : null} ;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper
      display='flex'
      alignItems='center'
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
            ', 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)'
            : '0px 2px 8px -3px ' + alpha(theme.colors.alpha.black[100], 0.2) + ', 0px 5px 22px -4px ' + alpha(theme.colors.alpha.black[100], 0.1),
      }}
    >
      <Stack direction='row' alignItems='center' spacing={1}>
        {/*<HeaderSearch />*/}
        <Box sx={{ display: { lg: 'none', md: 'flex' } }}>
          <Logo width={100} />
        </Box>
        <Permissions resource='any' action='Report'>
          <HeaderMenu />
        </Permissions>
      </Stack>
      <Box display='flex' alignItems='center'>
        {/* <HeaderButtons /> */}
        <HeaderUserBox />
        <Box
          component='span'
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          <Tooltip arrow title='Toggle Menu'>
            <IconButton color='primary' onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon fontSize='small' /> : <CloseTwoToneIcon fontSize='small' />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
