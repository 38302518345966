import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IWarehouse {
  id: number;
  name: string;
  address: string;
  city: string;
  state: {id: number; abbreviation: string; name: string};
  zipCode: string;
}

export interface IWarehouseTransfer {
  id: number;
  sourceName: string;
  destinationName: string;
  status: string;
  linesQuantity: number;
  createdAt: Date;
  createdBy: string;
  note: string;
}

export interface IWarehouseTransferPagination extends IPagination<IWarehouseTransfer> {}

export interface IWarehousePaginated extends IPagination<IWarehouse> {}

export interface ILocationItem {
  id: number;
  stock: number;
  totalStock: number;
  minStock: number;
  maxStock: number;
}

export interface ILocationItems {
  itemId: number;
  locations: Array<ILocationItem>;
}

export interface IWarehouseList {
  id: number;
  name: string;
}

export const warehouseResource = 'InventoryWarehouse';
export const warehouseTransferResource = 'InventoryWarehouseTransfer';

class WarehouseService extends HttpService {
  constructor() {
    super();

    this.updateWarehouseTransfer = this.updateWarehouseTransfer.bind(this);
    this.signWarehouseTransfer = this.signWarehouseTransfer.bind(this);
    this.postWarehouseTransfer = this.postWarehouseTransfer.bind(this);
    this.addWarehouseTransferNote = this.addWarehouseTransferNote.bind(this);
  }

  url = '/v1/Warehouses';

  async getWarehouses(page: number, pageSize: number): Promise<IWarehousePaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IWarehousePaginated>(this.url, config);

    return response.data;
  }

  async getAllWarehouses(itemId?: number): Promise<Array<IAutoComplete>> {
    let config: RawAxiosRequestConfig = {
      params: {
        itemId,
      },
    };

    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/All`, config);

    return response.data;
  }

  async getWarehousesList() {
    const {data} = await this.getBase<any>(`${this.url}/List`);

    let response = data.warehousesList;

    return response;
  }

  async getWarehousesTransfers(page: number, pageSize: number, filters: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        status: filters.status,
        signed: filters.signed,
        sourceId: filters.sourceId,
        destinationId: filters.destinationId,
      };
    }

    const response = await this.getBase<IWarehouseTransferPagination>(`${this.url}/Transfers`, config);

    return response.data;
  }

  async getWarehousesTransfer(id: any) {
    const response = await this.getBase<any>(`${this.url}/Transfer/${id}`);

    return response.data;
  }

  async getWarehousesItems(warehouseId: number) {
    const response = await this.getBase(`${this.url}/${warehouseId}/WarehouseItems`);

    return response.data;
  }

  async getItemTransfer(id: number) {
    const {data} = await this.getBase(`${this.url}/ItemTransfer/${id}`);
    return data;
  }

  async signWarehouseTransfer(values: {data: any; id: number}) {
    const {data: response} = await this.putBase(`${this.url}/Transfer/${values.id}/Sign`, values.data);

    return response;
  }

  addWarehouseTransferNote = async (values: {transferId: number; data: any}) => {
    const response = await this.putBase(`${this.url}/Transfer/${values.transferId}/Note`, values.data);

    return response.data;
  };

  async postWarehouseTransfer(data: any) {
    const response = await this.postBase(`${this.url}/Transfer`, data.data);

    return response.data;
  }

  async updateWarehouseTransfer(params: {transferId: number; data: any}) {
    const response = await this.putBase(`${this.url}/Transfer/${params.transferId}`, params.data);

    return response.data;
  }
}

export default new WarehouseService();
