import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IItem {
  id: number;
  name: string;
  sku: string;
  description: string;
  weight: number;
  length: number;
  height: number;
  image: {name: string; url: string; hasPlaceHolder: boolean};
  cost: number;
  bundleTracked: boolean;
  obsolete: boolean;
  sellable: boolean;
  purchaseTaxable: boolean;
  unit: {id: number; name: string};
  group: {id: number; name: string};
  quickBook: {id: number; name: string};
  color: {id: number; name: string; code: string};
  stocks: Array<{
    id: number;
    location: {id: number; name: string; warehouse: {id: number; name: string}};
    order: number;
    stock: number;
    bundle: number;
  }>;
}

interface IItemForPagination {
  id: number;
  name: string;
  sku: string;
  description: string;
  weight: number;
  length: number;
  height: number;
  image: string;
  cost: number;
  stock: number;
  bundle: number;
  ordered: number;
  bundleTracked: boolean;
  obsolete: boolean;
  sellable: boolean;
  unit: {id: number; name: string};
  group: {id: number; name: string};
  color: {id: number; name: string; code: string};
}

export interface IItemPaginated extends IPagination<IItemForPagination> {}

export interface IItemQuantities {
  id: number;
  name: string;
  sku: string;
  bundleTracked: boolean;
  warehouses: Array<{
    id: number;
    name: string;
    stock: number;
    transaction: number;
    bundle: number;
    locations: Array<{
      id: number;
      name: string;
      stock: number;
      transaction: number;
      bundle: number;
    }>;
  }>;
}

export interface IVendorItem {
  id: number;
  cost: number;
  stock: number;
}

export interface IPropsWarehouseItem {
  color: string;
  description: string;
  existsInWarehouses: number[];
  externalStock: number;
  id: number;
  image: string;
  maxStock: number;
  minStock: number;
  name: string;
  sku: string;
  stock: number;
  unit: {id: number; name: string};
  warehouseName: string;
}

export const itemResource = 'InventoryItem';
export const itemTransferResource = 'ItemsTransfers';

class ItemService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Items';

  Add(itemId: number) {
    return `${this.url}/${itemId}/Add`;
  }

  Subtract(itemId: number) {
    return `${this.url}/${itemId}/Subtract`;
  }

  Adjust(itemId: number) {
    return `${this.url}/${itemId}/Adjust`;
  }

  async getItems(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        id: filters?.id,
        name: filters?.name,
        sku: filters?.sku,
        description: filters?.description,
        colorId: filters?.colorId,
        itemTypeId: filters?.itemTypeId,
        measurementUnitId: filters?.measurementUnitId,
        warehouseId: filters?.warehouseId,
        // weight: filters?.weight,
        // length: filters?.length,
        // height: filters?.height,
        // sale: filters?.sale,
        // groupId: filters?.groupId,
        // measurementUnitId: filters?.measurementUnitId,
        // quickBookId: filters?.quickBookId,
        // obsolete: filters?.obsolete,
        // bundleTracked: filters?.bundleTracked,
        // sellable: filters?.sellable,
        // purchaseTaxable: filters?.purchaseTaxable,
      };
    }

    const response = await this.getBase<IItemPaginated>(this.url, config);

    return response.data;
  }

  async getItem(itemId: number): Promise<IItem> {
    const response = await this.getBase<IItem>(`${this.url}/${itemId}`);

    return response.data;
  }

  // Vendor
  async getVendorItem(vendorItemId: number): Promise<IVendorItem> {
    const response = await this.getBase<IVendorItem>(`${this.url}/VendorItems/${vendorItemId}`);

    return response.data;
  }

  // Quantities
  async getItemQuantities(itemId: number): Promise<IItemQuantities> {
    const response = await this.getBase<IItemQuantities>(`${this.url}/${itemId}/Quantities`);

    return response.data;
  }

  //Transactions
  async getTransactionsItems(page: number, pageSize: number, itemId: number, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page,
        pageSize,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        reconciled: filters?.value?.reconciled,
        warehouseId: filters?.value?.warehouseId,
        transTypeId: filters?.value?.transTypeId,
      };
    }

    const {data} = await this.getBase<any>(`${this.url}/${itemId}/Transactions`, config);

    return data;
  }

  async getTransactionsType() {
    const {data} = await this.getBase<any>(`${this.url}/TransactionsList`);

    return data;
  }

  async getWarehouseItem(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/WarehouseItem/${id}`);

    return data;
  }

  async getAllItems(item: string, warehouseId?: number) {
    const config: RawAxiosRequestConfig = {
      params: {
        warehouseId,
        item,
      },
    };

    const response = await this.getBase<any>(`${this.url}/List`, config);

    return response.data;
  }
}

export default new ItemService();
