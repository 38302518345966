import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IDeliveryTypePaginated extends IPagination<IAutoComplete> {}

export const deliveryTypeResource = 'SettingsDeliveryType';

class DeliveryService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/DeliveryTypes';

  async getDeliveryTypes(page: number, pageSize: number) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IDeliveryTypePaginated>(this.url, config);

    return response.data;
  }

  async getDeliveryTypeList() {
    const response = await this.getBase<IDeliveryTypePaginated>(this.url);

    return response.data;
  }
}

export default new DeliveryService();
