import {useRef, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';

import {useTheme} from '@mui/material';

// import SquareMenu from '@/layouts/AuthLayout/Header/Menu/SquareMenu';
import LineMenu from '@/layouts/AuthLayout/Header/Menu/LineMenu';
import usePermissions from '@/hooks/usePermissions';

import {itemResource} from '@/services/http/ItemService';
import {workOrderResource} from '@/services/http/WorkOrderService';

function HeaderMenu() {
  const theme = useTheme();

  const lineMenuRef = useRef<any>(null);
  const [lineMenuOpen, setLineMenuOpen] = useState<boolean>(false);

  const permissions = usePermissions();

  /*  const ref2 = useRef<any>(null);
    const [isOpen2, setOpen2] = useState<boolean>(false);
  
    const handleOpen2 = (): void => {
      setOpen2(true);
    };
  
    const handleClose2 = (): void => {
      setOpen2(false);
    };*/

  return (
    <>
      <Box
        sx={{
          display: {xs: 'none', sm: 'inline-flex'},
        }}
      >
        {permissions.has([itemResource, workOrderResource], 'Report') && (
          <Button
            ref={lineMenuRef}
            onClick={() => setLineMenuOpen(true)}
            endIcon={<KeyboardArrowDownTwoToneIcon />}
            color='secondary'
            size='small'
            sx={{
              ml: {sm: 2, lg: 0},
              mr: 1,
              px: 2,
              backgroundColor: `${theme.colors.secondary.lighter}`,
              color: `${theme.colors.secondary.dark}`,

              '.MuiSvgIcon-root': {
                color: `${theme.colors.secondary.dark}`,
                transition: `${theme.transitions.create(['color'])}`,
              },

              '&:hover': {
                backgroundColor: `${theme.colors.secondary.main}`,
                color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`,

                '.MuiSvgIcon-root': {
                  color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`,
                },
              },
            }}
          >
            Reports
          </Button>
        )}

        {/*<Button
          ref={ref2}
          onClick={handleOpen2}
          endIcon={<KeyboardArrowDownTwoToneIcon />}
          color='secondary'
          size='small'
          sx={{
            px: 2,
            backgroundColor: `${theme.colors.secondary.lighter}`,
            color: `${theme.colors.secondary.dark}`,

            '.MuiSvgIcon-root': {
              color: `${theme.colors.secondary.dark}`,
              transition: `${theme.transitions.create(['color'])}`,
            },

            '&:hover': {
              backgroundColor: `${theme.colors.secondary.main}`,
              color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`,

              '.MuiSvgIcon-root': {
                color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`,
              },
            },
          }}
        >
          Dashboards
        </Button>*/}
      </Box>
      <Popover
        disableScrollLock
        anchorEl={lineMenuRef.current}
        onClose={() => setLineMenuOpen(false)}
        open={lineMenuOpen}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <LineMenu setOpen={setLineMenuOpen} />
      </Popover>
      {/* <Popover
        disableScrollLock
        anchorEl={ref2.current}
        onClose={handleClose2}
        open={isOpen2}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={{'.MuiPopover-paper': {background: theme.colors.gradients.blue3}}}
      >
        <SquareMenu />
      </Popover>*/}
    </>
  );
}

export default HeaderMenu;
