import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IQuickBooksServer {
  id: number;
  name: string;
  userName: string;
  ownerId: string;
  fileId: string;
  lastSync: Date;
}

export interface IQuickBooksServerPaginated extends IPagination<IQuickBooksServer> {}

export const qbResource = 'QuickBooksServers';
export const getFileUrl = '/v1/QuickBooksServers/File';

export default new (class QuickBookServerService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/QuickBooksServers';

  async getQuickBooksServers(page: number, pageSize: number, searchTerm: string | undefined, filters?: any): Promise<IQuickBooksServerPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
      };
    }

    const response = await this.getBase<IQuickBooksServerPaginated>(this.url, config);

    return response.data;
  }

  async getAllQuickBookServers() {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/All`);

    return response.data;
  }
})();
