import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IEmailTypes {
  id: number;
  name: string;
  isShared: boolean;
}

export interface IEmailTypePaginated extends IPagination<IEmailTypes> {}

export const emailTypeResource = 'SettingsEmailType';

class EmailService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/EmailTypes';

  async getEmailTypes(page: number, pageSize: number) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IEmailTypePaginated>(this.url, config);

    return response.data;
  }

  async getEmailTypeList() {
    const response = await this.getBase<IEmailTypePaginated>(this.url);

    return response.data;
  }
}

export default new EmailService();
