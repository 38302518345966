import {RawAxiosRequestConfig} from 'axios';
import {IPagination} from './types';
import HttpService from '@/services/httpService';

export interface IAddressTypes {
  id: number;
  name: string;
}

export interface IAddressTypePaginated extends IPagination<IAddressTypes> {}

export const addressTypeResource = 'SettingsAddressType';

class AddressService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/AddressTypes';

  async getAddressTypes(page: number, pageSize: number) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<IPagination<IAddressTypes>>(this.url, config);

    return response.data;
  }

  async getAddressTypeList() {
    const response = await this.getBase<IAddressTypePaginated>(this.url);

    return response.data;
  }
}

export default new AddressService();
