import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { CloseOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface IDialogTitleProps {
  title: string;
  disableActions?: boolean;
  onClose?: () => void;
}

const DialogTitleStyled = styled(MuiDialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 8px 8px 20px !important',
}));

export default function DialogTitle(props: IDialogTitleProps) {
  const { title, disableActions, onClose } = props;

  function handleClose() {
    onClose?.();
  }

  return (
    <DialogTitleStyled>
      <Typography variant='h4'>{title}</Typography>
      {onClose && (
        <IconButton onClick={handleClose} disabled={disableActions}>
          <CloseOutlined />
        </IconButton>
      )}
    </DialogTitleStyled>
  );
}
