const useGlobalVariables = () => {
  const variables = {
    rollerShadeId: 1,
    zebraShadeId: 2,
    remoteId: 3,
    accessoriesId: 4,
    miscellaneousId: 5,
    multiShadeId: 6,
    fabricScreenId: 1,
    fabricBlackoutId: 2,
    fabricNeoluxId: 3,
    blobLogos: 'logos',
    blobInventory: 'inventory',
  }

  return variables
}

export default useGlobalVariables
