import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IItemUnit {
  id: number;
  name: string;
  entity: {
    id: number;
    name: string;
  };
}

export interface IItemUnitPaginated extends IPagination<IItemUnit> {}

export const itemUnitResource = 'SettingsItemUnit';

class ItemUnitService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/MeasurementUnits';

  async getItemUnits(page: number, pageSize: number, unit?: number): Promise<IItemUnitPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        MeasurementUnitId: unit,
      },
    };

    const response = await this.getBase<IItemUnitPaginated>(this.url, config);

    return response.data;
  }

  async getAllItemUnits(): Promise<Array<IAutoComplete>> {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/List`);

    return response.data;
  }

  async getUnitEntities() {
    const response = await this.getBase<any>(`${this.url}/Entities`);

    return response.data;
  }
}

export default new ItemUnitService();
