import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

interface LoadingProps {
  type?: 'linear' | 'table' | 'form' | 'input' | 'photos';
}

const Loading: React.FC<LoadingProps> = (props) => {
  const {type = 'linear'} = props;

  if (type === 'linear') {
    return <LinearProgress />;
  }

  if (type === 'table') {
    return (
      <Stack spacing={0}>
        <Skeleton variant='text' height={80} />
        <Skeleton variant='text' height={50} />
        <Skeleton variant='text' height={50} />
        <Skeleton variant='text' height={50} />
      </Stack>
    );
  }

  if (type === 'form') {
    return (
      <Stack spacing={0}>
        <Skeleton variant='text' height={70} />
        <Skeleton variant='text' height={70} />
        <Skeleton variant='text' height={70} />
        <Skeleton variant='text' height={70} />
        <Skeleton variant='text' height={70} />
      </Stack>
    );
  }

  if (type === 'input') {
    return <Skeleton variant='text' height={70} />;
  }

  if (type === 'photos') {
    return (
      <>
        <Stack spacing={1} direction='row'>
          <Skeleton variant='rectangular' width='33.33%' height={100} />
          <Skeleton variant='rectangular' width='33.33%' height={100} />
          <Skeleton variant='rectangular' width='33.33%' height={100} />
        </Stack>
        <Stack spacing={1} direction='row' sx={{marginTop: 1}}>
          <Skeleton variant='rectangular' width='33.33%' height={100} />
          <Skeleton variant='rectangular' width='33.33%' height={100} />
          <Skeleton variant='rectangular' width='33.33%' height={100} />
        </Stack>
      </>
    );
  }

  return null;
};

export default Loading;
