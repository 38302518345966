import {Fragment, ReactNode, FormEventHandler} from 'react';

import {DevTool} from '@hookform/devtools';
import {Control} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types/fields';
import {DefaultValues} from 'react-hook-form/dist/types/form';
import {Portal} from '@mui/base/Portal';

export interface FormProps<TFieldValues extends FieldValues, TContext> {
  id?: string;
  control: Control<TFieldValues, TContext>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  devTools?: boolean;
  children: ReactNode;
}

export type FormAction = 'add' | 'edit' | 'clone' | 'filter' | 'void';

export interface IFormExtras<TValue> {
  values?: DefaultValues<TValue>;
}

export interface IForm<TValue, TPropData = {}, TOriginalData = {}> {
  context: {
    action: FormAction;
  };
  originalData?: TOriginalData;
  propData: TPropData & IFormExtras<TValue>;
  onRequestNewProps: ({}) => void;
}

export default function Form<TFieldValues extends FieldValues, TContext>(props: FormProps<TFieldValues, TContext>) {
  const {id, control, onSubmit, devTools = true, children} = props;

  return (
    <Fragment>
      <form id={id} style={{display: 'contents'}} onSubmit={onSubmit} autoComplete='off' noValidate>
        {children}
      </form>
      {process.env.NEXT_PUBLIC_FORM_TOOL === 'true' && devTools && (
        <Portal>
          <DevTool control={control} placement='top-right' />
        </Portal>
      )}
    </Fragment>
  );
}
