import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ViewComfyRoundedIcon from '@mui/icons-material/ViewComfyRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';

import {processResources} from '@/services/http/MarkUnitStatusService';
import {qbResource} from '@/services/http/QuickBookServerService';
import {productResource, settingsAccessoryResource, settingsRollerShadeResource, settingsZebraShadeResource} from '@/services/http/ProductService';
import {vendorResource} from '@/services/http/VendorService';
import {itemUnitResource} from '@/services/http/ItemUnitService';
import {itemResource} from '@/services/http/ItemService';
import {warehouseResource, warehouseTransferResource} from '@/services/http/WarehouseService';
import {itemTypeResource} from '@/services/http/ItemTypeService';
import {itemColorResource} from '@/services/http/ItemColorService';
import {paymentTypeResource} from '@/services/http/PaymentService';
import {emailTypeResource} from '@/services/http/EmailService';
import {deliveryTypeResource} from '@/services/http/DeliveryService';
import {addressTypeResource} from '@/services/http/AddressService';
import {taxTypeResource} from '@/services/http/TaxService';
import {dispatchPackingSlipResource, packingSlipResource} from '@/services/http/PackingSlipService';
import {userResource} from '@/services/http/UserService';
import {packageResource, receivePackageResource} from '@/services/http/PackageService';
import {smallLabelResource, workOrderResource} from '@/services/http/WorkOrderService';
import {purchaseOrderResource} from '@/services/http/PurchaseOrderService';
import {roleResource} from '@/services/http/RoleService';
import {customerResource} from '@/services/http/CustomerService';
import {quoteResource} from '@/services/http/QuoteService';
import {orderResource} from '@/services/http/OrderService';
import {auditResource} from '@/services/http/AuditService';
import {promotionResource} from '@/services/http/PromotionService';

export interface MenuItem {
  link: string;
  icon?: React.ElementType;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;

  resource: string | Array<string>;
  action?: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Home',
        link: '/',
        icon: DashboardIcon,
        resource: 'any',
      },
    ],
  },
  {
    heading: 'General',
    items: [
      {
        name: 'Quotes',
        link: '/quotes',
        icon: NoteAltRoundedIcon,
        resource: [quoteResource],
      },
      {
        name: 'Customers',
        link: '/customers',
        icon: PeopleOutlinedIcon,
        resource: [customerResource],
      },
      {
        name: 'Production',
        link: '/production',
        icon: ShoppingCartCheckoutRoundedIcon,
        resource: [orderResource, workOrderResource],
        items: [
          {
            name: 'Orders',
            link: '/orders',
            resource: orderResource,
          },
          {
            name: 'Work Order',
            link: '/work-orders',
            resource: workOrderResource,
          },
        ],
      },
      {
        name: 'Shipping',
        link: '/shipping',
        icon: LocalShippingIcon,
        resource: [packageResource, packingSlipResource],
        items: [
          {
            name: 'Packages',
            link: '/shipping/packages',
            resource: [packageResource],
          },
          {
            name: 'Packing Slips',
            link: '/shipping/packing-slips',
            resource: [packingSlipResource],
          },
        ],
      },
      {
        name: 'Inventory',
        link: '/inventory',
        icon: WarehouseOutlinedIcon,
        resource: [itemResource, warehouseTransferResource, purchaseOrderResource],
        items: [
          {
            name: 'Items',
            link: '/inventory/items',
            resource: itemResource,
          },
          {
            name: 'Warehouse Transfers',
            link: '/inventory/warehouses/transfers',
            resource: warehouseTransferResource,
          },
          {
            name: 'Purchase Orders',
            link: '/inventory/purchase-orders',
            resource: purchaseOrderResource,
          },
        ],
      },
      {
        name: 'Scanners',
        link: '/scanners',
        icon: QrCodeScannerOutlinedIcon,
        resource: [
          dispatchPackingSlipResource,
          itemResource,
          smallLabelResource,
          warehouseTransferResource,
          packageResource,
          packingSlipResource,
          receivePackageResource,
          ...processResources,
        ],
        items: [
          {
            name: 'Item',
            link: '/scanner/item',
            resource: itemResource,
          },
          {
            name: 'Small Label',
            link: '/scanner/small-label',
            resource: smallLabelResource,
          },
          {
            name: 'Status',
            link: '/scanner/status',
            resource: processResources,
          },
          {
            name: 'Create package',
            link: '/scanner/package',
            resource: 'ProcessPackage',
            action: 'Create',
          },
          {
            name: 'Receive Package',
            link: '/scanner/package/receive',
            resource: receivePackageResource,
          },
          {
            name: 'Dispatch Packing Slip',
            link: '/scanner/packing-slip',
            resource: dispatchPackingSlipResource,
          },
          {
            name: 'Warehouse Transfer',
            link: '/scanner/transfers',
            resource: warehouseTransferResource,
          },
        ],
      },
    ],
  },
  {
    heading: 'Settings',
    items: [
      {
        name: 'Inventory',
        icon: WarehouseOutlinedIcon,
        link: '/settings/inventory',
        resource: [itemColorResource, itemTypeResource, itemUnitResource, warehouseResource, vendorResource],
        items: [
          {
            name: 'Colors',
            link: '/settings/inventory/colors',
            resource: itemColorResource,
          },
          {
            name: 'Item Types',
            link: '/settings/inventory/item-types',
            resource: itemTypeResource,
          },
          {
            name: 'Measurement Units',
            link: '/settings/inventory/units',
            resource: itemUnitResource,
          },
          {
            name: 'Vendors',
            link: '/settings/inventory/vendors',
            resource: vendorResource,
          },
          {
            name: 'Warehouses',
            link: '/settings/inventory/warehouses',
            resource: warehouseResource,
          },
        ],
      },
      {
        name: 'QuickBooks',
        icon: AccountBalanceWalletIcon,
        link: '/quick-books',
        resource: [qbResource],
        items: [
          {
            name: 'Severs',
            link: '/settings/quick-books/servers',
            resource: [qbResource],
          },
          {
            name: 'Jobs',
            link: '/settings/quick-books/jobs',
            resource: [qbResource],
          },
        ],
      },
      {
        name: 'General',
        icon: SettingsRoundedIcon,
        link: '/settings/general',
        resource: [
          promotionResource,
          taxTypeResource,
          addressTypeResource,
          deliveryTypeResource,
          emailTypeResource,
          paymentTypeResource,
          productResource,
        ],
        items: [
          {
            name: 'Tax Types',
            link: '/settings/general/tax-types',
            resource: taxTypeResource,
          },
          {
            name: 'Address Types',
            link: '/settings/general/address-types',
            resource: addressTypeResource,
          },
          {
            name: 'Delivery Types',
            link: '/settings/general/delivery-types',
            resource: deliveryTypeResource,
          },
          {
            name: 'Email Types',
            link: '/settings/general/email-types',
            resource: emailTypeResource,
          },
          {
            name: 'Payment Terms',
            link: '/settings/general/payment-terms',
            resource: paymentTypeResource,
          },
          {
            name: 'Promotions',
            link: '/settings/general/promotions',
            resource: promotionResource,
          },
          {
            name: 'Products',
            link: '/settings/general/products',
            resource: productResource,
          },
        ],
      },
      {
        name: 'Accessory',
        icon: ViewComfyRoundedIcon,
        link: '/settings/accessory',
        resource: [settingsAccessoryResource],
        items: [
          {
            name: 'Items',
            link: '/settings/accessory',
            resource: settingsAccessoryResource,
          },
        ],
      },
      {
        name: 'Roller Shade',
        icon: ViewComfyRoundedIcon,
        link: '/settings/roller-shade',
        resource: [settingsRollerShadeResource],
        items: [
          {
            name: 'Fabrics',
            link: '/settings/roller-shade/fabrics',
            resource: settingsRollerShadeResource,
          },
          {
            name: 'Configs',
            link: '/settings/roller-shade/configs',
            resource: settingsRollerShadeResource,
          },
        ],
      },
      {
        name: 'Zebra Shade',
        icon: ViewComfyRoundedIcon,
        link: '/settings/zebra-shade',
        resource: [settingsZebraShadeResource],
        items: [
          {
            name: 'Fabrics',
            link: '/settings/zebra-shade/fabrics',
            resource: settingsZebraShadeResource,
          },
          {
            name: 'Configs',
            link: '/settings/zebra-shade/configs',
            resource: settingsZebraShadeResource,
          },
        ],
      },
    ],
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Accounts',
        icon: ManageAccountsRoundedIcon,
        link: '/accounts',
        resource: [userResource, roleResource],
        items: [
          {
            name: 'Users',
            link: '/accounts/users',
            resource: 'AccountUsers',
          },
          {
            name: 'Roles',
            link: '/accounts/roles',
            resource: 'AccountRoles',
          },
        ],
      },
      {
        name: 'Audits',
        icon: PolicyRoundedIcon,
        link: '/audits',
        resource: [auditResource],
      },
    ],
  },
];

export default menuItems;
