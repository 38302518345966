import HttpService from '../httpService';

export interface IToken {
  expiration: string;
  token: string;
}

export interface ILoginResponse {
  accessToken: IToken;
  refreshToken: IToken;
  permissions: Array<string>;
}

class AuthService extends HttpService {
  constructor() {
    super();

    this.login = this.login.bind(this);
  }

  url = '/v1/auth';

  async login(data: {email: string; password: string; rememberMe?: boolean}) {
    const response = await this.postBase<ILoginResponse>(`${this.url}/Login`, {
      email: data.email,
      password: data.password,
      rememberMe: data.rememberMe !== undefined ? data.rememberMe : false,
    });

    return response.data;
  }
}

export default new AuthService();
