import { FC } from 'react';

import Image from 'next/image';

import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';

interface LogoProps {
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
  width?: number;
  height?: number;
}

const LogoWrapper = styled(Link)(
  ({ justifyContent }) => `
    display: flex;
    justify-content: ${justifyContent};
`
);

const Logo: FC<LogoProps> = ({ justifyContent = 'center', width = 150, height = 100 }) => {

  return (
    <LogoWrapper href='/' justifyContent={justifyContent}>
      <Image priority={true} src={'/static/images/logos/logo-head.svg'} width={width} height={height} alt='Image' />
    </LogoWrapper>
  );
};

export default Logo;
