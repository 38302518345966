import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface ITaxTypes {
  id: number;
  name: string;
  value: number;
  isShared: boolean;
}

export interface ITaxTypePaginated extends IPagination<ITaxTypes> {}

export const taxTypeResource = 'SettingsTaxType';

export default new (class TaxService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/TaxTypes';

  async getTaxTypes(page: number, pageSize: number): Promise<ITaxTypePaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<ITaxTypePaginated>(this.url, config);

    return response.data;
  }

  async getTaxTypesByCustomer(customerId: number): Promise<Array<ITaxTypes>> {
    const response = await this.getBase<Array<ITaxTypes>>(`${this.url}/Customer/${customerId}`);

    return response.data;
  }
})();
