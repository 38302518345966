import HttpService from '../httpService';

export const markUnitStatusResource = 'ProductionMarkUnitStatus';

export const processResources = [
  'ProcessOrder',
  'ProcessProcessed',
  'ProcessRelease',
  'ProcessCutFabric',
  'ProcessCutMetal',
  'ProcessAssemble',
  'ProcessPackage',
  'ProcessReceive',
  'ProcessDelivery',
];

interface IMarkUnitStatus {
  date: string;
  disabled: boolean;
  id: number;
  index: number;
  itemText: string;
  itemValue: number;
  processText: string;
  processValue: number;
  statusText: string;
  statusValue: string;
}

export interface IMarkUnitMark {
  id: number;
  name: string;
  product: string;
  quantity: number;
  statuses: IMarkUnitStatus[];
  markList: IMarkUnitMark[];
}

export interface IMarkUnitWorOrder {
  id: number;
  workOrderIdentifier: string;
  marks: IMarkUnitMark[];
}

export default new (class MarkStatusService extends HttpService {
  constructor() {
    super();

    this.deleteMarkUnitStatusNote = this.deleteMarkUnitStatusNote.bind(this);
    this.addNoteMarkUnitStatus = this.addNoteMarkUnitStatus.bind(this);
    this.holdMarkUnitStatus = this.holdMarkUnitStatus.bind(this);
    this.completeMarkUnitStatus = this.completeMarkUnitStatus.bind(this);
    this.inCompleteMarkUnitStatus = this.inCompleteMarkUnitStatus.bind(this);
  }

  url = '/v1/MarkUnitStatuses';

  async getMarkUnitStatusForWorkOrder(id: number) {
    const {data} = await this.getBase<any>(`${this.url}/WorkOrder/${id}`);

    return data;
  }

  async getMarkUnitStatusForAllMarkUnits(workOrderId: number, markId: number) {
    const {data} = await this.getBase<any>(`${this.url}/WorkOrder/${workOrderId}/Mark/${markId}`);

    return data;
  }

  async deleteMarkUnitStatusNote(id: number) {
    const {data: response} = await this.deleteBase(`${this.url}/Note/${id}`);

    return response;
  }

  async getMarkUnitStatusByMarkUnitId(id: number) {
    const {data} = await this.getBase(`${this.url}/MarkUnit/${id}`);

    return data;
  }

  async completeMarkUnitStatus(id: number) {
    const {data: response} = await this.putBase(`${this.url}/Complete/${id}`, {});

    return response;
  }

  async holdMarkUnitStatus(id: number) {
    const {data: response} = await this.putBase(`${this.url}/Hold/${id}`, undefined);
    return response;
  }

  async addNoteMarkUnitStatus(data: any) {
    const {data: response} = await this.putBase(`${this.url}/Note/${data.id}`, {
      note: data.note,
    });
    return response;
  }

  async inCompleteMarkUnitStatus(id: number) {
    const {data: response} = await this.putBase(`${this.url}/InComplete/${id}`, {});
    return response;
  }
})();
