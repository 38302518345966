import {KeyboardEvent, ReactNode} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import {Box, SxProps} from '@mui/material';

import Loading from '@/components/Utilities/Loading';
import DialogTitle from './shared/DialogTitle';

export type DialogMaxWidth = 'xs' | 'xl' | 'sm' | 'md' | 'lg';

export interface IDialogAction {
  actions: ReactNode;
}

export interface IContentDialogProps {
  title: string;
  description?: string;
  open: boolean;
  loading?: boolean;
  maxWidth?: DialogMaxWidth;
  fullScreen?: boolean;
  closeText?: string;
  onClose?: () => void;
  disableActions?: boolean;
  actions?: (({actions}: IDialogAction) => ReactNode) | null;
  children: ReactNode;
  contentSx?: SxProps;
  alertBox?: ReactNode;
}

export default function ContentDialog(props: IContentDialogProps) {
  const {
    title,
    description,
    open,
    loading,
    maxWidth = 'sm',
    fullScreen = false,
    closeText = 'Close',
    onClose,
    disableActions = false,
    actions,
    children,
    contentSx,
    alertBox,
  } = props;

  const renderActions = () => {
    if (actions === null) return null;

    function defaultActions() {
      return (
        <DialogActions>
          <Button disabled={disableActions || loading} onClick={onClose} color='warning'>
            {closeText}
          </Button>
        </DialogActions>
      );
    }

    if (actions) {
      return <DialogActions>{actions({actions: defaultActions()})}</DialogActions>;
    }

    return defaultActions();
  };

  function handleEscapeKey(event: KeyboardEvent<HTMLDivElement>) {
    if (event && event.key === 'Escape') {
      onClose?.();
    }
  }

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={true} fullScreen={fullScreen} onKeyUp={handleEscapeKey}>
      {loading && <Loading />}
      <DialogTitle title={title} onClose={onClose} disableActions={disableActions || loading} />
      <Box paddingLeft={2} paddingRight={2}>
        {alertBox}
      </Box>
      <DialogContent dividers={true} sx={contentSx}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      {renderActions()}
    </Dialog>
  );
}
