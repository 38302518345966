import {Dispatch, FC, SetStateAction} from 'react';

import {useRouter} from 'next/router';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

interface LineMenuItemProps {
  text: string;
  path: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const LineMenuItem: FC<LineMenuItemProps> = ({text, path, setOpen}) => {
  const router = useRouter();

  const handleOnClick = async (path: string) => {
    await router.push(path);
    setOpen(false);
  };

  return (
    <MenuItem
      onClick={async () => {
        await handleOnClick(path);
      }}
    >
      <ListItemText primaryTypographyProps={{variant: 'h5'}} primary={text} />
    </MenuItem>
  );
};

export default LineMenuItem;
