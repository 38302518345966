import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IPagination} from './types';

export interface IQuotesSeries {
  categories: Array<string>;
  series: Array<number>;
}

export interface IQuotes {
  id: number;
  name: string;
  markup: number;
  createdAt: Date;
  fromCustomer: {id: number; name: string};
  toCustomer: {id: number; name: string};
  finalCustomer: {id: number; name: string};
  user: {id: string; name: string};
}

export interface INotes {
  quoteId: number;
  notes: Array<{id: number; note: string; type: string}>;
}

export interface IQuoteMarks {
  id: number;
  quoteName: string;
  createdAt: Date;
  quoteEtc: Date;
  toCustomer: string;
  orderId: number;
  costSubTotal: number;
  costTaxes: number;
  costTotal: number;
  sellSubTotal: number;
  sellTaxes: number;
  sellTotal: number;
  sellDiscountTotal: number;
  costDiscountTotal: number;
  notes: Array<{id: number; note: string}>;
  sellQuoteDiscounts: {
    id: number;
    name: string;
    amount: number;
    discountType: string;
    discountValue: number;
  }[];
  items: Array<{
    discriminator: string;
    count: number;
    marks: Array<{
      id: number;
      name: string;
      quantity: number;
      lineOrder: number;
      discriminator: number;
      discriminatorText: string;
      qrCode: string;
      costEach: number;
      sellEach: number;
      sellDiscountEach: number;
      costDiscountEach: number;
      costTotal: number;
      sellTotal: number;
      costTaxesTotal: number;
      sellTaxesTotal: number;
      cutList: Array<{
        id: number;
        quantity: number;
        length1: number;
        length2: number;
        type: string;
        description: string;
        sku: string;
      }>;
    }>;
  }>;
}

export interface IQuotesPaginated extends IPagination<IQuotes> {}

export const markUrl = '/v1/Marks';
export const quoteResource = 'QuotesQuote';
export const quoteEtcResource = 'QuotesEtc';
export const markResource = 'QuotesMark';
export const markReportsResource = {
  sell: 'QuotesSell',
  cost: 'QuotesCost',
  costSell: 'QuotesCostSell',
  cutList: 'QuotesCutList',
};

class QuoteService extends HttpService {
  constructor() {
    super();

    this.postQuoteNote = this.postQuoteNote.bind(this);
    this.deleteQuoteNote = this.deleteQuoteNote.bind(this);
  }

  url = '/v1/Quotes';

  async getQuotesCount() {
    const response = await this.getBase<{total: number}>(`${this.url}/Count`);

    return response.data.total;
  }

  async getQuotesDaily() {
    const response = await this.getBase<IQuotesSeries>(`${this.url}/Daily`);

    return response.data;
  }

  async getQuotesMonthly() {
    const response = await this.getBase<IQuotesSeries>(`${this.url}/Monthly`);

    return response.data;
  }

  async getQuotes(page: number, pageSize: number, searchTerm: string | undefined, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== undefined) {
      config.params = {
        ...config.params,
        name: filters.name,
        markup: filters.markup,
        toCustomerId: filters.finalCustomerId,
        user: filters.user,
        creationStart: filters.creationStart,
        creationEnd: filters.creationEnd,
      };
    }

    const response = await this.getBase<IQuotesPaginated>(this.url, config);
    return response.data;
  }

  async getQuoteMarks(quoteId: number) {
    const response = await this.getBase<IQuoteMarks>(`${this.url}/${quoteId}/Marks`);

    return response.data;
  }

  //Notes
  async getHasNote(quoteId: number) {
    const response = await this.getBase<{hasNote: boolean}>(`${this.url}/${quoteId}/HasNote`);

    return response.data;
  }

  async getNotes(quoteId: number | undefined) {
    const response = await this.getBase<INotes>(`${this.url}/${quoteId}/Notes`);

    return response.data;
  }

  async deleteNote(quoteId: number, noteId: number) {
    const {data} = await this.deleteBase(`${this.url}/${quoteId}/Notes/${noteId}`);

    return data;
  }

  async getNoteTypes() {
    const {data} = await this.getBase<any>(`${this.url}/NoteTypes`);

    return data;
  }

  async postQuoteNote(params: {quoteId: number; values: {note: string; type: number}}) {
    const response = await this.postBase<INotes>(`${this.url}/${params.quoteId}/Note`, params.values);

    return response.data;
  }

  async deleteQuoteNote(params: {quoteId: number; noteId: number}) {
    const response = await this.deleteBase(`${this.url}/${params.quoteId}/Notes/${params.noteId}`);

    return response;
  }

  //multiShades
  async getMultiShadesByQuote(quoteId: number) {
    const {data} = await this.getBase<any>(`${this.url}/${quoteId}/MultiShades`);

    return data;
  }

  //Reports
  async getReport(id: number, params: {type?: string; cutList?: boolean}) {
    const config: RawAxiosRequestConfig = {
      responseType: 'arraybuffer',
      params: {
        type: params.type,
        cutList: params.cutList,
      },
    };

    const response = await this.getBase<any>(`${this.url}/${id}/Print`, config);

    return response.data;
  }
}

export default new QuoteService();
