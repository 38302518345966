import {FC, useState, useEffect, useRef, ChangeEvent} from 'react';

import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Box from '@mui/material/Box';

import ContentDialog from '@/components/Actions/Dialogs/ContentDialog';

import actionService from '@/services/http/ActionService';

import {useSnackbar} from 'notistack';
import Loading from '@/components/Utilities/Loading';
import {IGenericResponse} from '@/services/http/types';
import ConfirmDialog from '../Actions/dialogActions/ConfirmDialog';

export interface IImage {
  id?: number;
  name: string;
  url: string;
  file?: File | null;
}

interface IImageBase64Props {
  accept?: 'image/*';
  images?: Array<IImage>;
  columns?: number;
  maxImages?: number;
  maxWidth?: number;
  maxHeight?: number;
  addUrl?: string;
  deleteUrl?: string;
  allowChanges?: boolean;
  onSuccess?: (image: Array<string>, action: 'add' | 'remove') => void;
  onError?: () => void;
}

const ImageBase64: FC<IImageBase64Props> = (props: any) => {
  const {
    accept = 'image/*',
    images: imagesProp,
    columns = 1,
    maxImages = 1,
    maxWidth,
    maxHeight = 300,
    addUrl,
    deleteUrl,
    allowChanges = true,
    onSuccess,
    onError,
  } = props;

  const [images, setImages] = useState<Array<IImage>>([]);
  const [showImage, setShowImage] = useState<{open: boolean; image?: IImage}>({open: false});
  const [deleteImage, setDeleteImage] = useState<{open: boolean; image?: IImage}>({open: false});
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (imagesProp !== undefined && imagesProp.length > 0) {
      let values: Array<IImage> = imagesProp.map((image: IImage) => {
        let value: IImage = {id: image.id, name: image.name, url: image.url, file: null};
        return value;
      });

      setImages(values);
    } else if (images.length > 0 && imagesProp !== undefined && imagesProp.length === 0) {
      setImages([]);
    }
  }, [JSON.stringify(imagesProp)]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result!.toString());
      reader.onerror = (error) => reject(error);
    });
  };

  const getBase64Array = async (images: Array<IImage>) => {
    let base64Images = [];

    for (const image of images.filter((x) => x.file !== null)) {
      base64Images.push(await fileToBase64(image.file as File));
    }

    return base64Images;
  };

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    let file: File | undefined = undefined;

    if (files !== null && files.length > 0) {
      file = files[0];
    }

    if (file === undefined) return;

    const url = URL.createObjectURL(file);

    if (addUrl !== undefined) {
      try {
        setLoading(true);

        let response = await actionService.post<IGenericResponse<any>>(addUrl, {photo: await fileToBase64(file)});
        let newImageValue: Array<IImage> = [
          ...images,
          {
            id: response.data.data.id,
            name: file.name,
            url: response.data.data.url,
            file: null,
          },
        ];

        setImages(newImageValue);

        enqueueSnackbar('The image was successfully added.', {variant: 'success'});

        setLoading(false);
        if (onSuccess) onSuccess(await getBase64Array(newImageValue), 'add');
      } catch (error) {
        setLoading(false);
        if (onError) onError();
      }
    } else {
      let newImageValue = [...images, {name: file.name, url, file}];

      setImages(newImageValue);

      if (onSuccess) onSuccess(await getBase64Array(newImageValue), 'add');
    }
  };

  const handleOnOpen = () => {
    if (fileInputRef.current !== null) fileInputRef.current.click();
  };

  const handleDeleteImage = async () => {
    if (deleteImage.image?.file === null && deleteUrl !== undefined) {
      try {
        setLoading(true);

        let url = deleteImage.image.id !== undefined ? `${deleteUrl}/${deleteImage.image.id}` : deleteUrl;
        await actionService.delete<IGenericResponse>(url);

        let newImageValue = [...images.filter((_, index) => index != images.indexOf(deleteImage.image as IImage))];

        setImages(newImageValue);
        setDeleteImage({open: false});
        setLoading(false);

        enqueueSnackbar('The image was successfully deleted.', {variant: 'success'});
        if (onSuccess) onSuccess(await getBase64Array(newImageValue), 'remove');
      } catch (error) {
        setDeleteImage({open: false});
        setLoading(false);
        if (onError) onError();
      }
    } else {
      let newImageValue = [...images.filter((_, index) => index != images.indexOf(deleteImage.image as IImage))];

      setImages(newImageValue);
      setDeleteImage({open: false});
    }
  };

  const ImageViewer = () => {
    if (images.length === 0) return <p>There is no image</p>;

    return (
      <ImageList cols={columns} sx={{maxWidth, maxHeight}}>
        {images.map((image, index) => (
          <ImageListItem key={index} sx={{alignSelf: 'flex-start'}}>
            {image.file === null && <CheckCircleOutlinedIcon sx={{color: 'green', position: 'absolute', right: 0, margin: 0.5}} />}
            <img
              src={image.url}
              alt={image.name}
              style={{cursor: 'pointer', objectFit: 'cover'}}
              onClick={() => setShowImage({open: true, image})}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
            {isHovering && (
              <ImageListItemBar
                subtitle={image.name}
                actionIcon={
                  allowChanges ? (
                    <IconButton sx={{color: 'rgba(255, 255, 255, 0.54)'}} onClick={() => setDeleteImage({open: true, image})}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  ) : null
                }
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {allowChanges && (
        <>
          {!images.length >= maxImages && (
            <>
              <input
                style={{display: 'none'}}
                ref={fileInputRef}
                accept={accept}
                capture='environment'
                type='file'
                onChange={handleOnChange}
                onClick={(event) => {
                  const element = event.target as HTMLInputElement;
                  element.value = '';
                }}
              />
              <IconButton onClick={handleOnOpen} disabled={images.length >= maxImages}>
                <CameraAltOutlinedIcon fontSize='large' />
              </IconButton>
            </>
          )}
        </>
      )}
      {loading && addUrl !== undefined ? (
        <div style={{width: '100%', marginTop: 15}}>
          <Loading type='photos' />
        </div>
      ) : (
        <ImageViewer />
      )}

      <ContentDialog title='Image Viewer' maxWidth='md' open={showImage.open} onClose={() => setShowImage({open: false})}>
        <img src={showImage.image?.url} alt={showImage.image?.name} loading='lazy' style={{width: '100%'}} />
      </ContentDialog>
      <ConfirmDialog loading={loading} open={deleteImage.open} onConfirm={handleDeleteImage} onCancel={() => setDeleteImage({open: false})} />
    </Box>
  );
};

export default ImageBase64;
