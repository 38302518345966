import {FC, ReactNode} from 'react';

import {FormProvider, useForm, UseFormReturn} from 'react-hook-form';

import {zodResolver} from '@hookform/resolvers/zod';
import {ZodSchema} from 'zod';

import {joiResolver} from '@hookform/resolvers/joi';
import {Schema} from 'joi';
import Form from './Form';

interface FormContextProps {
  onSubmit: (data: any) => void;
  validationSchema?: ZodSchema<any> | Schema;
  defaultValues?: any;
  context?: UseFormReturn<any>;
  validationMode?: 'onChange' | 'onSubmit' | 'onBlur' | 'onTouched';
  devTools?: boolean;
  formId?: string;
  children: ReactNode;
}

const FormCore: FC<FormContextProps> = (props: any) => {
  const {defaultValues = {}, onSubmit = () => {}, devTools = true, validationMode = 'onChange', validationSchema, children, formId} = props;

  let resolverType = validationSchema instanceof ZodSchema ? zodResolver(validationSchema) : joiResolver(validationSchema);

  const methods = useForm<typeof defaultValues>({
    defaultValues,
    mode: validationMode,
    resolver: resolverType,
  });

  const {handleSubmit, control} = methods;

  return (
    <FormProvider {...methods}>
      <Form id={formId} onSubmit={handleSubmit(onSubmit)} control={control} devTools={devTools}>
        {children}
      </Form>
    </FormProvider>
  );
};

const FormContext: FC<FormContextProps> = (props: any) => {
  const {context, onSubmit, devTools = true, validationMode, validationSchema, children, formId} = props;

  if (!context) {
    return (
      <FormCore formId={formId} onSubmit={onSubmit} validationMode={validationMode} validationSchema={validationSchema} devTools={devTools}>
        {children}
      </FormCore>
    );
  }

  return (
    <FormProvider {...context}>
      <Form id={formId} onSubmit={context.handleSubmit(onSubmit)} control={context.control} devTools={devTools}>
        {children}
      </Form>
    </FormProvider>
  );
};

export default FormContext;
