import {FC, Dispatch, SetStateAction, Fragment} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';

import {styled, alpha, useTheme} from '@mui/material/styles';

import Permissions from '@/components/Utilities/Permissions';
import LineMenuTitle from '@/layouts/AuthLayout/Header/Menu/LineMenuTitle';
import LineMenuItem from '@/layouts/AuthLayout/Header/Menu/LineMenuItem';
import {itemResource} from '@/services/http/ItemService';
import {workOrderResource} from '@/services/http/WorkOrderService';
import {userResource} from '@/services/http/UserService';
import {orderResource} from '@/services/http/OrderService';

const MenuListWrapper = styled(MenuList)(
  ({theme}) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

interface LineMenuProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const LineMenu: FC<LineMenuProps> = (props: any) => {
  const {setOpen} = props;

  const theme = useTheme();

  return (
    <Fragment>
      <Box sx={{p: 2, background: alpha(theme.colors.alpha.black[100], 0.06)}} display='flex' alignItems='center' justifyContent='space-between'>
        <Box>
          <Typography variant='h4'>Reports</Typography>
        </Box>
      </Box>
      <Divider />
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        divider={<Divider orientation='vertical' flexItem />}
        justifyContent='stretch'
        alignItems='stretch'
        spacing={0}
      >
        <Permissions resource={itemResource} action='Report'>
          <MenuListWrapper>
            <LineMenuTitle text='Inventory' />
            <LineMenuItem text='Items' path='/reports/inventory/items' setOpen={setOpen} />
            <LineMenuItem text='Items Under Minimum' path='/reports/inventory/items/under-minimum' setOpen={setOpen} />
          </MenuListWrapper>
        </Permissions>
        <Permissions resource={[workOrderResource]} action='Report'>
          <MenuListWrapper>
            <LineMenuTitle text='Production' />
            <LineMenuItem text='Back Log by Departments' path='/reports/production/back-log-by-departments' setOpen={setOpen} />
            <LineMenuItem text='Completed by Departments' path='/reports/production/completed-departments' setOpen={setOpen} />
            <LineMenuItem text='WO Created by System' path='/reports/production/wo-created-system' setOpen={setOpen} />
          </MenuListWrapper>
        </Permissions>
        <Permissions resource={orderResource} action='Report'>
          <MenuListWrapper>
            <LineMenuTitle text='Accounting' />
            <LineMenuItem text='Orders Approved' path='/reports/production/orders-approved' setOpen={setOpen} />
          </MenuListWrapper>
        </Permissions>
        <Permissions resource={userResource} action='Report'>
          <MenuListWrapper>
            <LineMenuTitle text='Account' />
            <LineMenuItem text='Sales by Representatives' path='/reports/account/sales-by-rep' setOpen={setOpen} />
          </MenuListWrapper>
        </Permissions>
        {/* <MenuListWrapper>
          <LineMenuTitle text='Temp 2' />
          <MenuItem>
            <ListItemText primaryTypographyProps={{ variant: 'h5' }} primary='Calendar' />
          </MenuItem>
        </MenuListWrapper> */}
      </Stack>
    </Fragment>
  );
};

export default LineMenu;
