import {FC, ReactElement, ReactNode} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Loading from '@/components/Utilities/Loading';

import {styled} from '@mui/material/styles';
import {SxProps} from '@mui/material';

const DialogTitleStyled = styled(DialogTitle)(
  () => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 20px !important;
`
);

interface ContentActionProps {
  title: string;
  contentText?: string;
  loading?: boolean;
  hideClose?: boolean;
  open: boolean;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'xl' | 'sm' | 'md' | 'lg';
  onClose: () => void;
  children: ReactNode;
  actions?: ReactElement;
  contentSx?: SxProps;
}

const ContentDialog: FC<ContentActionProps> = (props: any) => {
  const {title, contentText, loading, open, fullScreen = false, maxWidth = 'sm', onClose, children, actions, hideClose = false, contentSx} = props;

  return (
    <Dialog fullScreen={fullScreen} open={open} maxWidth={maxWidth} fullWidth={true}>
      {loading && <Loading />}
      <DialogTitleStyled>
        <Typography variant='h4'>{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent dividers={true} sx={contentSx}>
        {contentText && <DialogContentText>{contentText}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {actions === undefined ? (
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        ) : (
          <>
            {actions}
            {!hideClose && (
              <Button onClick={onClose} color='warning'>
                Close
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ContentDialog;
