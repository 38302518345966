import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete, IPagination} from './types';

export interface IPurchaseOrderAvailableReceive {
  id: number;
  locationId: number;
  warehouseId: number;
  lines: Array<{
    name: string;
    orderedQuantity: number;
    availableQuantity: number;
    bundleTracked: boolean;
    purchaseOrderLineId: number;
  }>;
}

export interface IPurchaseOrderCalendar {
  events: Array<{
    id: number;
    title: string;
    description: string;
    vendorAbbr: string;
    allDay: boolean;
    color: string;
    start: string;
    end: string;
  }>;
  legend: Array<{vendor: {id: number; name: string}; color: string}>;
}

export interface IPurchaseOrderReceipt {
  id: number;
  createdAt: string;
  createdBy: {id: string; name: string};
  count: number;
  notes: number;
  photos: number;
}

export interface IPurchaseOrderReceiptPaginated extends IPagination<IPurchaseOrderReceipt> {}

export interface IPurchaseOrder {
  id: number;
  status: 'Created' | 'Ordered' | 'Partial' | 'Received';
  createdAt: Date;
  updatedAt: Date;
  vendor: {id: number; name: string};
  warehouse: {id: number; name: string};
}

export interface IPurchaseOrderPaginated extends IPagination<IPurchaseOrder> {}

export interface IPurchaseOrderWithLines {
  id: number;
  createdAt: Date;
  eta: Date;
  createdBy: {id: string; name: string};
  vendor: {id: number; name: string};
  warehouse: {id: number; name: string};
  status: 'Created' | 'Ordered' | 'Partial' | 'Received';
  downloads: number;
  itemLines: number;
  itemTotal: number;
  subTotal: number;
  tax: number;
  total: number;
  lines: Array<{
    id: number;
    quantity: number;
    originalQuantity: number;
    received: number;
    bundle: number;
    costEach: number;
    costTotal: number;
    note: string;
    item: {
      id: number;
      vendorSku: string;
      sku: string;
      name: string;
      description: string;
      unit: string;
      length?: number;
    };
  }>;
}

interface INote {
  id: number;
  text: string;
  type: 'Vendor' | 'Note';
}

interface IItem {
  costPrice: number;
  id: number;
  itemName: string;
  lineOrder: number;
  measurementUnit: string;
  note: string;
  quantity: number;
  received: number;
  sku: string;
  totalPrice: number;
  vendorItemId: number;
  vendorSku: string;
}

export interface IPurchaseOrderDetails {
  createdAt: Date;
  id: number;
  itemsQuantity: number;
  itemsTotalCount: number;
  notes: INote[];
  purchaseOrderItems: IItem[];
  status: 'Created' | 'Ordered' | 'Partial' | 'Received';
  subtotal: number;
  tax: number;
  total: number;
  updatedAt: Date;
  vendorId: number;
  vendorName: string;
  warehouseId: number;
  warehouseName: string;
}

export interface IItemReceipt {
  poId: number;
  items: {
    name: string;
    poItemId: number;
    quantity: number;
    receivedQuantity: number;
  }[];
}

export const purchaseOrderItemUrl = '/v1/PurchaseOrderItems';
export const purchaseOrderResource = 'InventoryPurchaseOrder';

export default new (class PurchaseOrderService extends HttpService {
  constructor() {
    super();

    this.postPurchaseOrderNote = this.postPurchaseOrderNote.bind(this);
    this.deletePurchaseOrderNote = this.deletePurchaseOrderNote.bind(this);
    this.postPurchaseOrderItem = this.postPurchaseOrderItem.bind(this);
    this.putPurchaseOrderItem = this.putPurchaseOrderItem.bind(this);
  }

  url = '/v1/PurchaseOrders';

  async getPurchaseOrders(page: number, pageSize: number, searchTerm: string | undefined, filters?: any): Promise<IPurchaseOrderPaginated> {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
        searchTerm: searchTerm,
      },
    };

    if (filters !== null) {
      config.params = {
        ...config.params,
        vendorId: filters?.vendorId,
        status: filters?.status,
      };
    }

    const {data} = await this.getBase<IPurchaseOrderPaginated>(this.url, config);
    return data;
  }

  async getPurchaseOrder(purchaseOrderId: number): Promise<IPurchaseOrderWithLines> {
    const response = await this.getBase<IPurchaseOrderWithLines>(`${this.url}/${purchaseOrderId}`);

    return response.data;
  }

  async getPurchaseOrderAvailableReceive(purchaseOrderId: number): Promise<IPurchaseOrderAvailableReceive> {
    const response = await this.getBase<IPurchaseOrderAvailableReceive>(`${this.url}/${purchaseOrderId}/AvailableReceive`);

    return response.data;
  }

  async getPurchaseOrderReceipts(purchaseOrderId: number, page?: number, pageSize?: number) {
    let config: RawAxiosRequestConfig = {
      params: {
        page: page,
        pageSize: pageSize,
      },
    };

    const response = await this.getBase<any>(`${this.url}/${purchaseOrderId}/Receipts`, config);

    return response.data;
  }

  async getPurchaseOrderAvailableItems(purchaseOrderId: number): Promise<Array<IAutoComplete>> {
    const response = await this.getBase<Array<IAutoComplete>>(`${this.url}/${purchaseOrderId}/AvailableItems`);

    return response.data;
  }

  async getPurchaseOrderCalendar(month: number): Promise<IPurchaseOrderCalendar> {
    const response = await this.getBase<IPurchaseOrderCalendar>(`${this.url}/Calendar/${month}`);

    return response.data;
  }

  async refreshTax(userId: number) {
    return `${this.url}/${userId}/Tax`;
  }

  async putPurchaseOrderItem(values: {poItemId: number; data: any}) {
    const response = await this.putBase(`${purchaseOrderItemUrl}/${values.poItemId}`, values.data);

    return response.data;
  }

  async postPurchaseOrderItem(values: {poId: number; data: any}) {
    const response = await this.postBase(`${this.url}/${values.poId}/Items`, values.data);

    return response.data;
  }

  async getPurchaseOrderItems(purchaseOrderId: number) {
    const {data} = await this.getBase<any>(`${this.url}/${purchaseOrderId}/PurchaseOrderItems`);

    return data;
  }

  async getPOrderItemsReceipts(purchaseOrderId: number) {
    const {data} = await this.getBase<any>(`${this.url}/${purchaseOrderId}/ItemsReceipts`);

    return data;
  }

  async getNotes(poId: number) {
    const {data} = await this.getBase<any>(`${this.url}/Notes/${poId}`);

    return data;
  }

  async getNoteTypes() {
    const {data} = await this.getBase<any>(`${this.url}/NotesTypes/`);

    return data;
  }

  async postPurchaseOrderNote(params: {purchaseOrderId: number; values: {note: string; noteType: number}}) {
    const response = await this.postBase(`${this.url}/${params.purchaseOrderId}/Note`, params.values);

    return response.data;
  }

  async deletePurchaseOrderNote(noteId: number) {
    const response = await this.deleteBase(`${this.url}/Note/${noteId}`);

    return response;
  }
})();
