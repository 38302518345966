import {useState, useEffect, Fragment} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';

import useGlobalVariables from '../../hooks/useGlobalVariables';
import {useFormContext} from 'react-hook-form';

import Joi from 'joi';

import TextField from '@mui/material/TextField';
import {Box, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import customerService from "@/services/http/CustomerService";

import usePermissions from '@/hooks/usePermissions';

import ImageBase64 from '@/components/Utilities/ImageBase64';
import FormContext from '@/components/Forms/FormContext';
import { profileResource } from '@/services/http/UserService';


export const logoValidation = Joi.object({
  logo: Joi.string(),
});

const CustomerInformationForm = ({data}) => {
  const defaultValues = {
    id: '',
    name: '',
    logo: {},
    phone: '',
    emails: [],
    addresses: [],
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const {blobLogos} = useGlobalVariables();

  const {setValue} = useFormContext();
  const permission = usePermissions();

  const {data: currentCustomer} = useQuery({
    queryFn: ()=>customerService.getCurrentCustomerDetail(),
    queryKey: ['customerService.getCurrentCustomerDetail'],
  });

  useEffect(() => {
    if (currentCustomer !== undefined) {
      let customerValues = {
        id: currentCustomer.id,
        name: currentCustomer.name,
        logo: currentCustomer.logo.url,
        phone: currentCustomer.phone,
        emails: currentCustomer.emails.map((email) => {
          return {
            id: email.id,
            email: email.address,
            emailType: email.emailType.name,
          };
        }),
        addresses: currentCustomer.addresses.map((address) => {
          return {
            id: address.id,
            main: address.main,
            city: address.city,
            state: address.state !== null ? address.state.name : 0,
            zipCode: address.zipCode,
            isUsa: address.isUsa,
          };
        }),
      };
      setFormValues(customerValues);
    }
  }, [currentCustomer]);

  return (
    <Fragment>
      <div>
        {!permission.has(profileResource, 'Update') ? (
          <Box style={{justifyContent: 'center', display: 'flex'}}>
            <img src={formValues?.logo} style={{width: 200}} />
          </Box>
        ) : (
          <ImageBase64
            maxWidth={200}
            deleteUrl={`${customerService.url}/${formValues.id}/Logo`}
            images={!currentCustomer?.logo?.hasPlaceHolder ? [{url: formValues?.logo, name: 'Logo'}] : []}
            onSuccess={(image, action) => {
              if (action === 'add') {
                setValue('logo', image[0], {shouldValidate: true, shouldDirty: true});
              }
            }}
          />
        )}
        <TextField fullWidth disabled label='Name' margin='dense' value={formValues.name} variant='standard' />
        <TextField fullWidth disabled label='Phone' margin='dense' value={formValues.phone} variant='standard' />

        <Typography variant='subtitle1' display='block'>
          Emails
        </Typography>
        {formValues.emails.map((email, index) => {
          return (
            <Grid key={email.id} container spacing={1}>
              <Grid item md={8} xs={12}>
                <TextField fullWidth disabled label={`Email #${index + 1}`} margin='dense' value={email.email} variant='standard' />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField fullWidth disabled label={'Type'} margin='dense' value={email.emailType} variant='standard' />
              </Grid>
            </Grid>
          );
        })}

        <Typography variant='subtitle1' display='block'>
          Addresses
        </Typography>
        {formValues.addresses.map((address, index) => {
          return (
            <Grid key={address.id} container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField fullWidth disabled label={`Address #${index + 1}`} margin='dense' value={address.main} variant='standard' />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField fullWidth disabled label='City' margin='dense' value={address.city} variant='standard' />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  disabled
                  label='State'
                  margin='dense'
                  variant='standard'
                  value={address.state != null ? address.state : 'Out USA'}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField disabled fullWidth label='Zip Code' margin='dense' value={address.zipCode} variant='standard' />
              </Grid>
              <FormControlLabel
                control={<Checkbox disabled margin='dense' checked={address.isUsa} name='isUsa' color='primary' />}
                label='USA Address'
              />
            </Grid>
          );
        })}
      </div>
    </Fragment>
  );
};

export default CustomerInformationForm;
